import React, { useEffect, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams, Redirect } from 'react-router-dom';
import { Grid, Box, Typography, styled } from '@mui/material';
import EcgLoader from '../../Shared/EcgLoader';
import { getPatientData, emptyPatientData, ecgReviewPagination } from 'store/actions';
import { COMPLETTED, DOCTOR_ROUTES, INCOMMING, LOADING_MESSAGE, incoming } from 'constants/app';
import { useState } from 'react';
import {
  checkRequestStatus,
  getECGInboxTableData,
  getRequestPagination,
  requestReviewScreenNavinationRoute,
} from 'Services/ecgInboxService';
import { useHistory } from 'react-router';
import {
  getEKGAlgorithmDeterminationDisplayText,
  getEKGAlgorithmDeterminationStackFilter,
} from 'constants/recordingMaps';
import EcgReview from 'components/ekg/EkgReview/EcgReview';
import BasicBreadcrumbs from 'components/Shared/BreadCrumb/BreadCrumb';
import { formatDate, timeFormat } from 'utilities/Utilities';

const MainContainer = styled(Box)(({}) => ({
  fontFamily: 'Work Sans',
}));

const EkgReview = () => {
  const { id, reviewStatus } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  // Component level State for handling pagination Index
  const [isPendingmodalOpen, setIspendingmodalOpen] = useState(false);
  const [totalPaginationCount, setTotalPaginationCount] = useState(false);

  // Redux Data
  const profile = useSelector((state) => state.user.profile);
  const patientData = useSelector((state) => state.ekgReview.patientInfo);
  const isLoadingPatientData = useSelector((state) => state.ekgReview.isLoadingPatientData);
  const errorGettingPatientData = useSelector((state) => state.ekgReview.errorGettingPatientData);
  const isSubmittingReview = useSelector((state) => state.ekgReview.isSubmittingReview);
  const incominglistData = useSelector((state) => state.ekgListTable.ecgReviewPaginationArray);
  const ecgpageNumberValue = useSelector((state) => state.ekgListTable.ecgTablePageNumber);
  const isPhysician = profile.permissions.isPhysician;
  const { isAdmin } = profile.permissions;
  const tabState = useSelector((state) => state.ekgList.tabState);
  const userCountry = useSelector((state) => state.user.profile.country);
  const isregionupdated= useSelector((state) => state.user.isregionupdated)
  const apiQueryParams = {
    pageSize: 100 * ecgpageNumberValue,
    page: 1,
    status: reviewStatus === incoming ? INCOMMING : COMPLETTED,
    search: '',
    sortOrder: reviewStatus === incoming ? 'asc' : 'desc',
    sortBy: reviewStatus === incoming ? 'timeRequested' : 'timeSubmitted',
    filters: null,
  };

  const fetchEcgData = () => {
    getECGInboxTableData(apiQueryParams).then((res) => {
      // redux store dispatch to update pagination array for both incoming and completed
      dispatch(ecgReviewPagination(getRequestPagination(res?.result, reviewStatus)));
      setTotalPaginationCount(res?.totalCount);
    });
  };
  // Get patient Detail Info
  useEffect(() => {
    dispatch(getPatientData(id, reviewStatus, isregionupdated));
    // apiQueryParams.page=ecgpageNumberValue
    fetchEcgData();
    return () => {
      // source.cancel();
      dispatch(emptyPatientData());
    };
  }, [id]);

  // To determine the AI Determination status of header and body
  const aiDeterminationDetails = getEKGAlgorithmDeterminationDisplayText(
    patientData.interpretation,
    patientData.algorithmPackage,
    patientData.heartRate,
  );
  const interpretation = getEKGAlgorithmDeterminationStackFilter(
    patientData.interpretation,
    patientData.algorithmPackage,
    patientData.heartRate,
  );

  // To identify the 1st index for the Pagination
  const currentReviewId = useMemo(() => {
    const result =
      incominglistData && incominglistData.filter((val) => val.reportId == patientData?.id);
    return result[0]?.id;
  }, [incominglistData, patientData]);

  // Which handles next report details for pagination
  const handleNext = () => {
    const filterreportid =
      incominglistData && incominglistData.filter((val) => val.id === currentReviewId + 1);
    if (filterreportid.length === 0 || filterreportid[0]?.reportId == undefined) {
      history.push(DOCTOR_ROUTES.TELEKARDIA_ECG);
    } else if (checkRequestStatus(filterreportid[0]?.reportId)) {
      filterreportid &&
        requestReviewScreenNavinationRoute(
          history,
          reviewStatus,
          filterreportid[0]?.reportId,
          isAdmin,
        );
    } else handleNext();
  };

  //which handles previous report details for pagination
  const handleprevious = () => {
    const filterreportid =
      incominglistData && incominglistData.filter((val) => val.id === currentReviewId - 1);

    if (filterreportid.length === 0 || filterreportid[0]?.reportId == undefined) {
      history.push(DOCTOR_ROUTES.TELEKARDIA_ECG);
    } else if (checkRequestStatus(filterreportid[0].reportId)) {
      filterreportid &&
        requestReviewScreenNavinationRoute(
          history,
          reviewStatus,
          filterreportid[0]?.reportId,
          isAdmin,
        );
    } else handleprevious();
  };

  return (
    <MainContainer>
      <div style={{ margin: '-10px 0 20px 20px' }}>
        <BasicBreadcrumbs
          data={[
            {
              type: 'image',
              url: isAdmin
                ? DOCTOR_ROUTES.TELEKARDIA_ADMIN_DASHBOARD
                : DOCTOR_ROUTES.TELEKARDIA_DASHBOARD,
              src: 'Home',
            },
            { type: 'text', name: 'ECG Inbox', url: DOCTOR_ROUTES.TELEKARDIA_ECG },
            {
              type: 'text',
              name: `${formatDate(patientData?.recordedAt, userCountry)} ${timeFormat(
                patientData?.recordedAt,
              )}`,
            },
          ]}
        />
      </div>
      {isLoadingPatientData && (
        <Box style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <EcgLoader />
        </Box>
      )}
      {Object.keys(patientData).length !== 0 && !isLoadingPatientData && (
        <Box style={{ position: 'relative' }}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <EcgReview
                aiDeterminationDetails={aiDeterminationDetails}
                interpretation={interpretation}
                patientData={patientData}
                currentReviewId={currentReviewId}
                handleNext={handleNext}
                handleprevious={handleprevious}
                patientId={id}
                tabState={tabState}
                symptoms={
                  patientData.symptoms
                    ? patientData.symptoms
                        .reduce((str, ele) => (str += ele + ', '), '')
                        .slice(0, -2)
                    : ''
                }
                isAdmin={isAdmin}
                isPendingmodalOpen={isPendingmodalOpen}
                totalPaginationCount={totalPaginationCount}
                fetchEcgData={fetchEcgData}
              />
            </Grid>
          </Grid>
        </Box>
      )}
      {errorGettingPatientData && isPhysician && (
        <Redirect to={DOCTOR_ROUTES.TELEKARDIA_ECG} replace={true} />
      )}
      {errorGettingPatientData && isAdmin && (
        <Redirect to={DOCTOR_ROUTES.ADMIN_ECGLIST} replace={true} />
      )}
    </MainContainer>
  );
};

export default EkgReview;
