import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Box, Typography, Button } from "@material-ui/core";

import CustomDateRangePicker from "../CustomDateRangePicker/CustomDateRangePicker";
import { useStyles } from "./DateFilterStyles";
import { getPriorDate } from "utilities/Utilities";
import { REPORT_TYPE_MENU_OPTIONS } from "constants/ekgReportMenuOptionsConstants";
import { isClearClickedStatus } from "store/actions";

function DateFilter(props) {
  const { onDateSelection, selectedReportType, isExportClicked ,userCountry } = props;
  let selectedDateArray = [];
  const maxDate = new Date();
 

  const getEndOfDayInEU = (startDate) => {
    const date = new Date(startDate);
    // EU Timezone Offset (e.g., CET is UTC+1 in minutes)
    const EU_TIMEZONE_OFFSET = 1 * 60; // 1 hour in minutes
    const localTimezoneOffset = date.getTimezoneOffset();
  
    // Adjust to EU local time by adding the difference between EU offset and local offset
    const euTime = new Date(date.getTime() + (EU_TIMEZONE_OFFSET - localTimezoneOffset) * 60 * 1000);
    euTime.setHours(23, 59, 59, 999);
  
    return euTime;
  };
 
  const [selectedDateFilter, setSelectedDateFilter] = useState(0);
  const [selectedDate, setSelectedDate] = useState([null, null]);
  const [isCustomButtonClicked, setIsCustomButtonClicked] = useState(false);
  const [isDisabled, setIsDisabled] = useState(false);

  const isClearClicked = useSelector((state) => state.ekgReport.isClearClicked);
  const classes = useStyles();
  const dispatch = useDispatch();

  const onDaysFilterSelect = (priorDays, filterNumber) => {
    setIsCustomButtonClicked(false);
    setSelectedDateFilter(filterNumber);
    const daysPrior = getPriorDate(priorDays);
    const maxDate =(userCountry !== "US" && userCountry !== "SA" && userCountry !== "CA" && priorDays !== 7 && priorDays !== 30)  ? getEndOfDayInEU(daysPrior)  : new Date();
    selectedDateArray.push(daysPrior);
    selectedDateArray.push(maxDate);
    setSelectedDate(selectedDateArray);
  };

  const customButtonHandler = (filterNumber) => {
    setSelectedDateFilter(filterNumber);
    setSelectedDate([null, null]);
    setIsCustomButtonClicked(true);
  };

  onDateSelection(selectedDate);

  useEffect(() => {
    if (
      selectedReportType === REPORT_TYPE_MENU_OPTIONS[1].reportType ||
      selectedReportType === REPORT_TYPE_MENU_OPTIONS[2].reportType
    ) {
      setSelectedDateFilter(2);
      setSelectedDate([new Date(), new Date()]);
      setIsDisabled(true);
    } else {
      setSelectedDateFilter(0);
      setSelectedDate([null, null]);
      setIsDisabled(false);
    }
    setIsCustomButtonClicked(false);
  }, [selectedReportType]);

  useEffect(() => {
    setSelectedDateFilter(0);
    setSelectedDate([null, null]);
    setIsCustomButtonClicked(false);
    setIsDisabled(false);
    onDateSelection([]);
    dispatch(isClearClickedStatus(false));
  }, [isClearClicked, dispatch, onDateSelection]);


  return (
    <Box className={classes.container}>
      <Box className={classes.headingContainer}>
        <Typography className={classes.heading}>Date: </Typography>
      </Box>
      <Box className={classes.subHeadingContainer}>
        <Typography
          className={`${classes.subHeading} ${
            isExportClicked && (!selectedDate[0] || !selectedDate[1])
              ? classes.errorSubHeading
              : ""
          }`}
        >
          Select Date*
        </Typography>
      </Box>
      <Box className={classes.dateFilterContainer}>
      {!isCustomButtonClicked && (
          <Button
            className={`${classes.buttonStyle} ${
              classes.rightMostButtonStyle
            } ${selectedDateFilter === 6 ? classes.selectedDateFilter : ""}`}
            onClick={() => {
              customButtonHandler(6);
            }}
            disabled={isDisabled}
          >
            Custom
          </Button>
        )}
        {isCustomButtonClicked && (
          <Box className={classes.dateRangePickerContainer}>
            <CustomDateRangePicker
              selectedDate={selectedDate}
              setSelectedDate={setSelectedDate}
              setSelectedDateFilter={setSelectedDateFilter}
              disabled={isDisabled}
              userCountry={userCountry}
            />
          </Box>
        )}
        <Button
          disabled={false}
          className={`${classes.buttonStyle} ${classes.leftMostButtonStyle} ${
            selectedDateFilter === 2 ? classes.selectedDateFilter : ""
          }`}
          onClick={() => {
            onDaysFilterSelect(0, 2);
          }}
        >
          Today
        </Button>
        <Button
          className={`${classes.buttonStyle} ${
            selectedDateFilter === 3 ? classes.selectedDateFilter : ""
          }`}
          onClick={() => {
            onDaysFilterSelect(1, 3);
          }}
          disabled={isDisabled}
        >
          Yesterday
        </Button>
        <Button
          className={`${classes.buttonStyle} ${
            selectedDateFilter === 4 ? classes.selectedDateFilter : ""
          }`}
          onClick={() => {
            onDaysFilterSelect(7, 4);
          }}
          disabled={isDisabled}
        >
          Last 7 Days
        </Button>
        <Button
          className={`${classes.buttonStyle} ${
            selectedDateFilter === 5 ? classes.selectedDateFilter : ""
          }`}
          onClick={() => {
            onDaysFilterSelect(30, 5);
          }}
          disabled={isDisabled}
        >
          Last 30 Days
        </Button>
      </Box>
    </Box>
  );
}

export default DateFilter;
