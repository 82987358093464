import React from 'react'
import {
    Box,
    Button,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    ThemeProvider,
    Typography,
    styled,
    useTheme,
    Grid
  } from '@mui/material';
import { useSelector } from 'react-redux';
import EcgLoader from 'components/Shared/EcgLoader';
import { AUTH_TYPE } from 'constants/app';
import { parseName } from 'utilities/Utilities';
import { homeScreenButtonText } from './constants/DashBoardConstant';
import {  Paper } from '@material-ui/core';

  const Root = styled(TableContainer)(({ theme }) => ({
  minWidth: 650,

  '& .MuiTableCell-root': {
    fontFamily: 'Work Sans !important',
    fontSize: '16px',
  },
  '& .MuiTableHead-root': {
    background: '#F1F2F3',
    borderBottom: '1px solid #B4BBC0',
    alignSelf: 'stretch',
    '& .MuiTableCell-root': {
      fontWeight: 700,
    },
  },
  '& .MuiTableBody-root': {
    '& .MuiTableCell-root': {
      fontWeight: 400,
    },
  },
  '& .MuiTableRow-root': {
    '&:nth-of-type(even)': {
      backgroundColor: '#F0F8F5 !important',
    },
    '&:hover': {
      backgroundColor: '#F5F5F5 !important',
    },
  },
  '& .MuiTypography-h5': {
    fontSize: '20px',
    lineHeight: '23px',
    fontFamily: 'Work Sans',
    fontWeight: 500,
    margin: '20px',
  },
  '& .MuiTypography-h6': {
    fontSize: '16px',
    lineHeight: '20px',
    fontFamily: 'Work Sans',
    fontWeight: 400,
    margin: '20px',
  },
  '& .MuiTypography-body1': {
    color: '#067F6F',
    fontSize: '16px',
    fontWeight: 600,
    '&:hover': {
      textDecoration: 'underline',
      cursor: 'pointer',
    },
  },
  '& .MuiTypography-body2': {
    margin: 20,
    textAlign: 'center',
    fontSize: '18px',
    fontWeight: 500,
    fontColor: 'grey',
    fontFamily: 'Work Sans',
  },
}));


const CoachDashboardTable = ({sessionData ,navigate ,moment ,upcomingSessionInWaitroom, StatusColumn ,AppointmentStatusTypes ,SessionChannelTypeId ,isTeleconnectStarted ,isFloatingWindow ,videoCall ,audioCall ,completeSession}) => {
    const theme = useTheme();
    const isLoading = useSelector((state) => state.appointments.loading);
    const usertype = useSelector((state) => state.user.chatProfile.userType);

  
 return (
  <Paper>
<Root>
      <ThemeProvider theme={theme}>
        {isLoading ? (
          <EcgLoader />
        ) : (
          <>
            <Box>
              <Typography variant="h5" gutterBottom>
                Today's Session
              </Typography>
              {/* <Typography variant="h6">{moment(new Date()).format('MMM Do YYYY')}</Typography> */}
            </Box>
            <Table aria-label="simple table">
              {sessionData.length > 0 && (
                <TableHead>
                  <TableRow hover>
                    <TableCell>
                      <b>Time</b>
                    </TableCell>
                    <TableCell>
                      <b>{usertype === AUTH_TYPE.COACH ? `Member` : `Patient`}</b>
                    </TableCell>
                    <TableCell>
                      <b>Status</b>
                    </TableCell>
                    <TableCell></TableCell>
                  </TableRow>
                </TableHead>
              )}
              <TableBody>
                {sessionData.map((row, index) => (
                  <TableRow key={index}>
                    <TableCell>
                      {`${moment(new Date(row.scheduledStartTime)).format('LT')} -
                        ${moment(new Date(row.scheduledEndTime)).format('LT')}`}
                    </TableCell>
                    <TableCell component="th" scope="row">
                      <Typography
                        variant="body1"
                        onClick={() =>
                          navigate(
                            row.patient.patientId,
                            row.appointmentId,
                            row.appointmentStatusId,
                          )
                        }
                      >
                        {parseName(row.patient.firstName, row.patient.lastName)}
                      </Typography>
                    </TableCell>
                    <TableCell>
                      {upcomingSessionInWaitroom.length > 0 &&
                      upcomingSessionInWaitroom[0]?.id === row.id ? (
                        <StatusColumn data={upcomingSessionInWaitroom[0]?.title} />
                      ) : (
                        <StatusColumn data={row.appointmentStatus} />
                      )}
                    </TableCell>
                    <TableCell>
                      {((row.appointmentStatusId === AppointmentStatusTypes.MemberInWaitingRoom &&
                        row.appointmentChannelTypeId !== SessionChannelTypeId.AS_SCHEDULED_PHONE) ||
                        (upcomingSessionInWaitroom[0]?.id === row.id &&
                          upcomingSessionInWaitroom[0]?.status ===
                            AppointmentStatusTypes.MemberInWaitingRoom)) && (
                        <Button
                          variant="outlined"
                          disabled={isTeleconnectStarted || isFloatingWindow}
                          onClick={() =>
                            videoCall(
                              row.appointmentId,
                              `${row.patient.lastName}, ${row.patient.firstName}`,
                            )
                          }
                        >
                          {homeScreenButtonText.INITIATE_VIDEO_CALL}
                        </Button>
                      )}
                      {row.appointmentStatusId === AppointmentStatusTypes.InProgress &&
                        row.appointmentChannelTypeId !==
                          SessionChannelTypeId.AS_SCHEDULED_PHONE && (
                          <Button
                            variant="outlined"
                            disabled={isTeleconnectStarted || isFloatingWindow}
                            onClick={() =>
                              videoCall(
                                row.appointmentId,
                                row.patient.firstName,
                                row.patient.lastName,
                              )
                            }
                          >
                            {homeScreenButtonText.REJOIN_VIDEO_CALL}
                          </Button>
                        )}
                      {row.appointmentChannelTypeId === SessionChannelTypeId.AS_SCHEDULED_PHONE &&
                        (row.appointmentStatusId === AppointmentStatusTypes.Scheduled ||
                          row.appointmentStatusId === AppointmentStatusTypes.InProgress ||
                          row.appointmentStatusId ===
                            AppointmentStatusTypes.Encounter_Summary_Preparation) && (
                          <Button
                            variant="contained"
                            disabled={isTeleconnectStarted || isFloatingWindow}
                            onClick={() => audioCall(row.patient.patientId, row.appointmentId)}
                          >
                            {row.appointmentStatusId ===
                            AppointmentStatusTypes.Encounter_Summary_Preparation
                              ? homeScreenButtonText.REJOIN_AUDIO_CALL
                              : homeScreenButtonText.INITIATE_AUDIO_CALL}
                          </Button>
                        )}
                      {row.appointmentStatusId ===
                        AppointmentStatusTypes.Encounter_Summary_Preparation && (
                        <Button
                          variant="outlined"
                          onClick={() => completeSession(row.patient.patientId, row.appointmentId)}
                        >
                          {homeScreenButtonText.COMPLETE_SESSION_NOTES}
                        </Button>
                      )}
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </>
        )}
        {!isLoading && sessionData.length === 0 && (
          <>
            <Typography variant="body2">No session scheduled for today</Typography>
          </>
        )}
      </ThemeProvider>
    </Root>
  </Paper>
    
  );
};

export default CoachDashboardTable
