/* eslint-disable */
import React, { useState, useEffect, useCallback } from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { Box, Typography, Dialog, IconButton, Grid, Divider } from '@material-ui/core';
import MuiDialogTitle from '@material-ui/core/DialogTitle';
import MuiDialogContent from '@material-ui/core/DialogContent';
import CloseIcon from '@material-ui/icons/Close';
import _get from 'lodash.get';
import BottomLineTitle from '../Shared/Typography/BottomLineTitle';
import apiservice from 'api/services/apiservice.service';
import { streamToPromise, getTimezoneName } from 'utilities/Utilities';
import moment from 'moment';
import EcgLoader from '../Shared/EcgLoader';
import MuiTypography from '../Shared/Typography/MuiTypography';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme) => ({
  root: {},
  verticalgrid: {
    minHeight: 600,
  },
  cardHeader: {
    fontWeight: '600',
  },
  darkcyanGrid: {
    fontWeight: '600',
    alignItems: 'center',
    minHeight: '62.16px',
    padding: theme.spacing(1),
    background: '#142A39',
    color: '#FFFFFF',
  },
  blackGrid: {
    fontWeight: '600',
    alignItems: 'center',
    minHeight: '58px',
    padding: theme.spacing(1),
    background: '#000000',
    color: '#FFFFFF',
  },
  gridPadding: {
    padding: theme.spacing(1),
  },
  gridPaddingLightgreen: {
    background: '#DFF0E9',
    minHeight: '120px',
    minWidth: '180px',
    borderRadius: '10px',
    padding: theme.spacing(1),
  },
  mediumboldFont: {
    width: '64px',
    height: '12px',
    left: '17px',
    top: '151px',
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '12px',
    color: '#000000',
  },
  answergrid: {
    paddingTop: '10px',
    paddingBottom: '10px',
  },
  mediumboldFontfreesize: {
    fontStyle: 'normal',
    fontWeight: 400,
    fontSize: '14px',
    lineHeight: '16px',
    color: '#000000',
  },
  boldFont: {
    fontWeight: 600,
    color: '#000000',
  },
  greenFont: {
    fontWeight: 600,
    fontSize: '24px',
    // fontFamily: "SF Pro Text !important",
    color: '#2D9F86',
  },
  disclaimerFont: {
    fontWeight: 'normal',
    fontSize: '10px',
    lineHeight: '10px',
    color: '#000000',
  },
  grayFont: {
    width: '24px',
    height: '13px',
    left: '18px',
    top: '137px',
    fontSize: '14px',
    lineHeight: '12px',
    color: '#536063',
  },
  grayFontfreesize: {
    left: '18px',
    top: '137px',
    fontSize: '14px',
    lineHeight: '12px',
    color: '#536063',
  },
  bolderFont: {
    fontWeight: '700',
  },
  mediumBoldTitleFont: {
    fontWeight: '500',
    fontSize: '24px',
    lineHeight: '28px',
  },
  bolderdarktealFont: {
    fontWeight: '700',
    fontSize: '48px',
    lineHeight: '56px',
    color: '#415B6A',
  },
  cardlayout: {
    minHeight: 600,
    background: '#F1F1F1',
  },
  cardlayoutmini: {
    minHeight: 280,
    // background: "#F1F1F1",
  },
  recordingInfo: {
    zIndex: 1,
    padding: '8px 10px',
    minHeight: '30px',
    position: 'relative',
    marginLeft: '600px',
    top: '42px',
    backgroundColor: '#fff',
    opacity: 0.8,
    width: '300px',
    display: '-webkit-box' && '-moz-box' && '-ms-flexbox' && '-webkit-flex' && 'flex',
    flexDirection: 'row',
    justifyContent: 'flex-end',
  },
  recordingInfospan: {
    // width: "30px",
    paddingRight: '20px',
  },
  recordingInfospanfirstChild: {
    width: '30px',
    paddingLeft: '0px',
  },
  paperStyle: {
    padding: theme.spacing(1),
  },
}));

const styles = (theme) => ({
  root: {
    margin: 0,
  },
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
});

const DialogTitle = withStyles(styles)((props) => {
  const { children, classes, onClose, ...other } = props;
  return (
    <MuiDialogTitle disableTypography className={classes.root} {...other}>
      <Typography variant="h6">{children}</Typography>
      {onClose ? (
        <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
          <CloseIcon />
        </IconButton>
      ) : null}
    </MuiDialogTitle>
  );
});

const DialogContent = withStyles((theme) => ({
  root: {
    // padding: theme.spacing(2),
  },
}))(MuiDialogContent);

const FeedbackModal = (props) => {
  const classes = useStyles();
  const { t } = useTranslation();
  let appointmentId = props.appointmentId;

  const [feedbackDetails, setFeedbackDetails] = useState({});
  const [isloading, setisloading] = useState(false);
  const teamId = useSelector((state) => state.user.team.id);
  const timezoneValue = getTimezoneName();
  const loadAction = useCallback(async () => {
    setisloading(true);
    let offsetInMinutes = new Date().getTimezoneOffset();
    const appointmentRequest = streamToPromise(
      apiservice.getapiservice({
        baseUrl: `api/v1.1/teams/${teamId}/surveys/appointments/${appointmentId}?timeZoneOffset=${offsetInMinutes}&timeZone=${timezoneValue}`,
      }),
    );
    const appointments = await appointmentRequest;
    setFeedbackDetails(appointments.data);
    setisloading(false);
  }, []);

  useEffect(() => {
    loadAction();
    //preventing memory leaks
    return () => {
      setFeedbackDetails({});
      setisloading(false);
    };
  }, []);

  return (
    <div>
      <Dialog
        fullWidth={true}
        onClose={props.handleClose}
        aria-labelledby="customized-dialog-title"
        open={props.show}
      >
        {isloading === true ? (
          <EcgLoader />
        ) : (
          <>
            <DialogTitle id="customized-dialog-title" onClose={props.handleClose}>
              <Box px={2}>
                <Typography className={classes.mediumBoldTitleFont}>
                  {_get(feedbackDetails, 'title') || '-'}
                </Typography>
                <BottomLineTitle />
              </Box>
            </DialogTitle>
            <DialogContent>
              <Grid
                container
                direction="row"
                justify="space-between"
                style={{ display: '-ms-flexbox' }}
              >
                <Grid item xs className={classes.verticalgrid} style={{ display: '-ms-flexbox' }}>
                  <Grid container direction="row" spacing={3}>
                    <Grid item xs={8}>
                      <Grid container direction="column" justify="space-between">
                        {Object.keys(feedbackDetails).length > 0 &&
                          feedbackDetails.surveyQuestions.length > 0 &&
                          feedbackDetails.surveyQuestions.map((item, index) => (
                            <Box key={index}>
                              <Grid item>
                                <MuiTypography
                                  fontSize="16px"
                                  fontFamily="Work Sans"
                                  lineHeight="24px"
                                  letterSpacing="-0.2px"
                                  fontWeight={700}
                                  color="#142A39"
                                >
                                  {index + 1}.
                                  {_get(item, 'localizationString.textValue')?.replace(
                                    'consultation',
                                    'session',
                                  )}
                                </MuiTypography>
                              </Grid>
                              <Grid item className={classes.answergrid}>
                                <MuiTypography
                                  fontSize="16px"
                                  fontFamily="Work Sans"
                                  lineHeight="24px"
                                  letterSpacing="-0.2px"
                                  fontWeight={400}
                                  color="#142A39"
                                >
                                  {_get(item, 'userAnswerText')}
                                </MuiTypography>
                              </Grid>
                            </Box>
                          ))}
                      </Grid>
                    </Grid>
                    <Divider orientation="vertical" flexItem />
                    <Grid item xs={3}>
                      <Grid container direction="column" justify="space-between">
                        <Grid item>
                          <Grid item className={classes.gridPaddingLightgreen}>
                            <Grid
                              container
                              direction="column"
                              alignItems="center"
                              justify="center"
                              spacing={2}
                            >
                              <Grid
                                item
                                container
                                direction="row"
                                alignItems="flex-end"
                                justify="center"
                              >
                                <Grid item>
                                  <MuiTypography
                                    fontSize="48px"
                                    fontFamily="Work Sans"
                                    lineHeight="56px"
                                    fontWeight={500}
                                    color="#415B6A"
                                  >
                                    {_get(feedbackDetails, 'nps')}
                                  </MuiTypography>
                                </Grid>
                                <Grid item>
                                  <MuiTypography
                                    fontSize="24px"
                                    fontFamily="Work Sans"
                                    lineHeight="45.15px"
                                    fontWeight={500}
                                    color="#415B6A"
                                  >
                                    /10
                                  </MuiTypography>
                                </Grid>
                              </Grid>
                              <Grid item>
                                <MuiTypography
                                  fontSize="16px"
                                  fontFamily="Work Sans"
                                  lineHeight="24px"
                                  letterSpacing="-0.2px"
                                  fontWeight={700}
                                  color="#415B6A"
                                >
                                  {t('Member satisfaction')}
                                </MuiTypography>
                              </Grid>
                            </Grid>
                          </Grid>
                        </Grid>

                        <Grid item>
                          <MuiTypography
                            fontSize="16px"
                            fontFamily="Work Sans"
                            lineHeight="24px"
                            letterSpacing="-0.2px"
                            fontWeight={700}
                            color="#142A39"
                          >
                            {t('Consultation date & time')}
                          </MuiTypography>
                        </Grid>
                        <Grid item>
                          <MuiTypography
                            fontSize="16px"
                            fontFamily="Work Sans"
                            lineHeight="24px"
                            letterSpacing="-0.2px"
                            fontWeight={400}
                            color="#142A39"
                          >
                            {moment(_get(feedbackDetails, 'appointmentDateTime')).format('LLL')}
                          </MuiTypography>
                        </Grid>
                        <Grid item>
                          <br />
                        </Grid>
                        <Grid item>
                          <MuiTypography
                            fontSize="16px"
                            fontFamily="Work Sans"
                            lineHeight="24px"
                            letterSpacing="-0.2px"
                            fontWeight={700}
                            color="#142A39"
                          >
                            {t('Provider')}
                          </MuiTypography>
                        </Grid>
                        <Grid item>
                          <MuiTypography
                            fontSize="16px"
                            fontFamily="Work Sans"
                            lineHeight="24px"
                            letterSpacing="-0.2px"
                            fontWeight={400}
                            color="#142A39"
                          >
                            {_get(feedbackDetails, 'physicianName') || '-'}
                          </MuiTypography>
                        </Grid>
                      </Grid>
                    </Grid>
                  </Grid>
                </Grid>
              </Grid>
            </DialogContent>
          </>
        )}
      </Dialog>
    </div>
  );
};

export default FeedbackModal;
